@use "../../theme/colors";
@use "../../theme/shadows";
@use "../../theme/breakpoints";

body {
  overscroll-behavior-y: auto;
}
.fixed {
  position: fixed;
}
.insight {
  width: 100%;
  flex-grow: 1;

  position: relative;
  font-family: "Poppins", sans-serif;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: colors.get("neutral", 50);

  &-content {
    background-color: colors.get("primary", 50);

    overflow-y: auto;

    display: flex;
    flex-direction: column;

    width: 100%;
    @include breakpoints.breakpoint(tablet, min) {
      flex-direction: row;
      padding-top: 40px;
      width: min(1200px, 80vw);
    }

    &-wrapper {
      background-color: colors.get("neutral", 0);
      padding: 16px;
      // border-bottom: 1px solid colors.get("neutral", 100);

      @include breakpoints.breakpoint(tablet, min) {
        flex-grow: 1;
      }

      &-guest-view-lock {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 24px;
        img {
          min-height: 120px;
        }
      }
    }

    &-container {
      padding: 16px;
      background-color: colors.get("primary", 50);
      padding-bottom: 32px;
      padding-top: 25px;
      @include breakpoints.breakpoint(tablet, min) {
        flex-shrink: 1;
        max-width: 500px;
        min-width: 400px;
        padding: 16px 16px 16px 16px;
      }
    }

    &-widget {
      cursor: pointer;
      // padding-bottom: 100px;
      &-chevron {
        position: relative;
        float: right;
        top: 16px;
        right: 16px;
        font-size: 16px;
        color: colors.get("neutral", 600);
      }

      box-shadow: shadows.get("card");
    }

    &-widget-last {
      margin-bottom: 80px;
    }

    &-footer {
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0px 0 20px 0;
      background-color: transparent;

      &-btn {
        box-shadow: shadows.get("button");
        background-color: colors.get("primary", 500);
        border-radius: 24px;
        color: colors.get("neutral", 50);
        padding: 12px 16px;
        border: none;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 137px;
        min-height: 48px;
        max-height: 48px;
        text-decoration: none;
        img {
          margin-left: 10px;
        }
      }
    }
  }
  &-pull-to-refresh {
    position: fixed;
    top: -50px;
    width: 100%;
    height: 50px;
    transition: top 0.4s ease-in-out;
    z-index: 0;
    text-align: center;
    &.visible {
      top: 0;
      z-index: 1;
    }
    &-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: colors.get("primary", 500);
      font-weight: 600;
      width: 100%;
      &-text {
        background-color: colors.get("neutral", 50);
        border-radius: 20px;
        padding: 8px 16px 8px 16px;
      }
    }
  }
}

.ptr-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: colors.get("neutral", 400);
  z-index: 10;
  text-align: center;
  height: 50px;
  transition: all;
}

.ptr-element .genericon {
  opacity: 0.6;
  font-size: 34px;
  width: auto;
  height: auto;
  transition: all 0.25s ease;
  transform: rotate(90deg);
  margin-top: 5px;
}
.ptr-refresh .ptr-element .genericon {
  transform: rotate(270deg);
}
.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
  display: none;
}

.loading {
  display: inline-block;
  text-align: center;
  opacity: 0.4;
  margin: 12px 0 0 5px;
  display: none;
}
.ptr-loading .loading {
  display: block;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  transform: scale(0.3);
  border-radius: 50%;
  animation: ptr-loading 0.4s infinite alternate;
}

.loading-ptr-1 {
  animation-delay: 0 !important;
}

.loading-ptr-2 {
  animation-delay: 0.2s !important;
}

.loading-ptr-3 {
  animation-delay: 0.4s !important;
}

@keyframes ptr-loading {
  0% {
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    background-color: colors.get("neutral", 900);
    opacity: 1;
  }
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
  transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
  transform: translate3d(0, 0, 0);
}

.ptr-loading .refresh-view {
  transform: translate3d(0, 30px, 0);
}

.refresh-view.disabled {
  transform: translate3d(0px, 0px, 0px) !important;
}

.ptr-element.disabled {
  display: none !important;
}
