@use "../../theme/colors";

.hyfe_input {
  display: flex;
  max-width: 100%;
  width: 340px;
  height: 3rem;
  padding: 0rem 1.5rem;
  align-items: center;
  gap: 1.5rem;

  border: 1px solid colors.get("primary", 600);
  border-radius: 1.5rem;

  outline: 2px solid transparent;

  color: colors.get("neutral", 600);

  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  &:focus {
    outline-color: colors.get("primary", 700);
  }
}
