@use "../../theme/colors";
@use "../../theme/shadows";

.note {
  background: colors.get("neutral", 0);
  box-shadow: shadows.get("card");
  border-radius: 8px;
  padding: 16px;
  display: flex;
  margin-bottom: 16px;

  &-left {
    img {
      margin-right: 16px;
    }
  }

  &-right {
    &-title {
      color: colors.get("neutral", 900);
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 2px;
    }

    &-desc {
      color: colors.get("neutral", 800);
      font-size: 14px;
      line-height: 20px;
    }
  }
}
