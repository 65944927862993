@use "../../theme/colors";
@use "../../theme/breakpoints";

.rate {
  width: 100%;
  flex-grow: 1;

  font-family: "Poppins", sans-serif;
  background-color: colors.get("neutral", 50);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &-wrapper {
    width: 100%;
    @include breakpoints.breakpoint(tablet, min) {
      margin-top: 40px;
      width: min(1200px, 80vw);
    }
  }
  &-content {
    padding: 16px;
    background-color: colors.get("neutral", 0);
    text-align: left;
    padding-bottom: 4px;
    border-bottom: 1px solid colors.get("neutral", 100);

    &-section {
      margin-bottom: 20px;

      &-label {
        font-weight: 700;
        color: colors.get("neutral", 500);
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 4px;
      }

      &-wrapper {
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;

        &-main {
          color: colors.get("primary", 800);
          display: flex;
          align-items: baseline;

          &-value {
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
            margin-right: 4px;
          }

          &-unit {
            font-size: 20px;
            line-height: 26px;
            font-weight: 700;
            margin-right: 4px;
          }
        }

        &-sub {
          font-size: 20px;
          line-height: 26px;
          font-weight: 700;
          margin-right: 4px;
          color: colors.get("neutral", 300);
        }
      }

      .margin-top-10px {
        margin-top: 10px;
      }
      &-progress {
        display: flex;
        gap: 10px;
        justify-content: space-evenly;
        padding: 0px 16px 0px 16px;
        .progress-item-weekly {
          min-width: 44px;
        }
        &-item {
          min-width: 50px;
          flex: 1;
          &-label {
            color: colors.get("neutral", 800);
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            margin-top: 4px;
          }
        }
      }
    }
  }

  &-history {
    padding: 24px 16px;
    &-label {
      font-weight: 700;
      color: colors.get("neutral", 500);
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    &-item {
      padding: 12px 0px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid colors.get("neutral", 100);

      &-label {
        color: colors.get("neutral", 800);
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
      }

      &-value {
        font-size: 18px;
        color: colors.get("primary", 800);
        line-height: 26px;
        &-tracked {
          font-weight: 500;
        }
      }

      &-value-not {
        font-size: 14px;
        line-height: 20px;
        color: colors.get("neutral", 400);
      }
    }
  }
}
