@use "../../theme/colors";
@use "../../theme/breakpoints";

.trend {
  width: 100%;
  flex-grow: 1;

  font-family: "Poppins", sans-serif;
  background-color: colors.get("neutral", 50);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &-content {
    @include breakpoints.breakpoint(tablet, min) {
      width: min(1200px, 80vw);
      margin-top: 40px;
    }
    background-color: colors.get("neutral", 0);
    text-align: left;
    margin-bottom: 8px;
    border-bottom: 1px solid colors.get("neutral", 100);

    padding: 16px 16px 30px 16px;

    &-section {
      margin-bottom: 20px;
    }
    &-plot-wrapper {
      max-height: 10vh;
    }
  }

  &-footer {
    padding: 16px;
  }
}
