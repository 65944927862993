@use "../../theme/colors";

.date-picker {
  color: colors.get("primary", 500);
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  &-display {
    display: flex;
    &-text {
      padding: 5px;
    }
    &-chevron {
      width: 24px;
      height: 24px;
      padding-bottom: 1px;
    }
    &-input-hidden {
      position: fixed;
      color: transparent;
      background-color: transparent;
      width: 100%;
      display: block;
      border: none;
      z-index: 0;
    }
  }
}
