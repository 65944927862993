@use "../../theme/colors";

.progress {
  &_container {
    position: relative;
    height: 8px;

    &-back {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: colors.get("neutral", 200);
      border-radius: 40px;
    }

    &-back-empty {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: colors.get("neutral", 500);
      border-radius: 40px;
    }

    &-content {
      background-color: colors.get("primary", 500);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-radius: 40px;
      display: flex;
      justify-content: center;
    }

    &-empty {
      position: absolute;
      background-color: colors.get("error", 500);
      border-radius: 40px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &-slash {
        width: 16px;
        height: 3px;
        background-color: colors.get("neutral", 0);
        transform: rotate(45deg);
      }
    }

    &-future {
      position: absolute;
      background-color: colors.get("primary", 50);
      border: 1px solid colors.get("neutral", 200);
      border-radius: 40px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-title {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    font-weight: 500;
    color: colors.get("neutral", 800);
  }

  &-title-empty {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    font-weight: 500;
    color: colors.get("neutral", 300);
  }
}
