@use "../../theme/colors";
@use "../../theme/breakpoints";

.cough_today {
  margin: 8px 0 30px 0;
  font-family: "Poppins", sans-serif;

  &-header {
    &-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 2px;
      color: colors.get("neutral", 500);
      text-align: left;
      text-transform: uppercase;
    }

    &-main {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &-count {
        color: colors.get("primary", 800);
        font-size: 40px;
        line-height: 48px;
        font-weight: 700;
      }

      &-count-disabled {
        color: colors.get("neutral", 500);
        font-size: 40px;
        line-height: 48px;
        font-weight: 700;
      }

      &-date {
        color: colors.get("neutral", 800);
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
      }
    }
  }
}
