@use "../../theme/colors";

.loading_container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &_bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: colors.get("neutral", "modal-bg");
    &-contained {
      height: 100%;
    }
  }

  &_no_bg {
    background-color: transparent !important;
  }

  &-contained {
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: colors.get("neutral", "modal-bg");
    justify-content: center;
    align-items: center;
    z-index: 2; /* Make sure it does not overlap */
  }
}
