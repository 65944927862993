@use "../../theme/shadows";
@use "../../theme/colors";
@use "../../theme/breakpoints";

.login_card {
  display: flex;
  height: fit-content;

  padding: 6rem 2rem;
  max-width: 600px;
  gap: 3rem;

  @include breakpoints.breakpoint(mobile, max) {
    max-width: 90vw;
    min-width: 320px;
    padding: 2rem 2rem;
    gap: 1rem;
  }

  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-shadow: shadows.get("card");

  border-radius: 0.5rem;
  background: colors.get("neutral", 0);

  &__title {
    color: colors.get("primary", 900);
    text-align: center;
  }

  &__contents {
    max-width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    &__or_sep {
      color: colors.get("neutral", 600);
    }
  }

  &__success_msg {
    color: colors.get("success", 800);
  }
  &__error_msg {
    color: colors.get("error", 800);
  }
}
