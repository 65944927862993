@use "sass:color";
@use "../../theme/colors";
@use "../../theme/shadows";

.hyfe_button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 24px;
  border-width: 1px;
  border-style: solid;

  overflow: hidden;
  width: 100%;

  --hb-main-color: var(--primary-primary-500);
  --hb-highlight-color: var(--primary-primary-700);
  --hb-secondary-color: var(--neutral-neutral-0);
  --hb-disabled-color: var(--neutral-neutral-300);

  &.hb-error {
    --hb-main-color: var(--primary-primary-700);
    --hb-highlight-color: var(--primary-primary-900);
  }

  // Regular
  color: var(--hb-secondary-color);
  box-shadow: shadows.get("button");
  background-color: var(--hb-main-color);
  border-color: var(--hb-main-color);

  &:hover {
    background-color: var(--hb-highlight-color);
    border-color: var(--hb-highlight-color);
    box-shadow: none;
  }

  // Regular Disabled
  &.hb-disabled {
    background-color: var(--hb-disabled-color);
    border-color: var(--hb-disabled-color);
    box-shadow: none;
    cursor: not-allowed;
  }

  // Outline
  &.hb-outlined {
    color: var(--hb-main-color);
    border-color: var(--hb-main-color);
    background-color: var(--hb-secondary-color);

    &:hover {
      color: var(--hb-highlight-color);
      border-color: var(--hb-main-color);
      background-color: var(--hb-main-color);
    }

    &.hb-disabled {
      color: var(--hb-disabled-color) !important;
      border-color: var(--hb-disabled-color) !important;
      background-color: transparent !important;
      cursor: not-allowed;
    }
  }

  img {
    height: 100%;
    width: auto;
  }

  &.hb-loading {
    cursor: progress;
  }

  &.md {
    padding: 0.75rem 1.5rem;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.16px;
    height: 3rem;
  }

  &.sm {
    padding: 0.75rem 1rem;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.14px;
    height: 3.5rem;
  }

  &.apple {
    justify-content: left;
    gap: 1rem;
    background-color: #24282f;
    border-color: #24282f;
    color: colors.get("neutral", 0);

    box-shadow: shadows.get("button");

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    img {
      height: 1.5rem;
    }

    &:hover {
      background-color: color.scale(#24282f, $lightness: 7%);
      border-color: color.scale(#24282f, $lightness: 7%);
    }
  }

  &.google {
    justify-content: left;
    gap: 1rem;
    background-color: colors.get("neutral", 0);
    border-color: colors.get("neutral", 0);
    color: var(--dark-grey, #778397);

    box-shadow: shadows.get("button");
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    img {
      height: 1.5rem;
    }

    &:hover {
      background-color: color.scale(colors.get("neutral", 0), $lightness: -2%);
      border-color: color.scale(colors.get("neutral", 0), $lightness: -2%);
    }
  }
}
