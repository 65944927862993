@use "../../theme/colors";

.guest_view {
  font-family: "Poppins", sans-serif;
  background-color: colors.get("primary", 50);
  &-title {
    color: colors.get("primary", 900);
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    text-align: center;
  }

  &-desc {
    color: colors.get("neutral", 600);
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 24px;
  }

  &-last {
    margin-bottom: 100px;
  }
}
