@use "../../theme/colors";

.reference-bar {
  width: 100%;

  padding: 0px 16px 0px 16px;
  background-color: colors.get("neutral", 0);
  // border-bottom: 1px solid colors.get("neutral", 100);
  font-family: "Poppins", sans-serif;

  &-content {
    text-align: left;
    &-title {
      padding-top: 8px;
      margin-bottom: 12px;
      font-weight: 700;
      color: colors.get("neutral", 500);
      font-size: 14px;
    }
    &-bar {
      margin-bottom: 10px;
      &-sections {
        display: flex;
        flex-wrap: nowrap;
        &-item {
          height: 8px;
          flex-grow: 1;
          margin-right: 1px;
        }
        &-item:not(:last-child):not(:first-child) {
          margin-right: 1px;
        }
        &-item:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        &-item:last-child {
          margin-right: 0px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      &-values {
        display: flex;
        color: colors.get("neutral", 800);
        font-size: 14px;
        font-weight: 500;
        margin-top: 4px;
        > * {
          flex-grow: 1;
        }
        &-min {
          text-align: left;
        }
        &-max {
          text-align: right;
        }
      }
    }
  }
}
