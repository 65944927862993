@use "../../theme/colors";
@use "../../theme/breakpoints";

.login_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  // min-width: calc(100vw - 15px);
  background: var(--gradient-gradient-primary, linear-gradient(119deg, #294a9c 0%, #2f86ac 100%));
  z-index: 0;
}

.login {
  display: flex;
  flex-direction: column;

  width: fit-content;
  max-width: 1600px;

  padding: 1rem;

  &__main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & > * {
      flex-basis: 50%;
    }

    &__screenshot {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 40vw;
        @include breakpoints.breakpoint(mobile, max) {
          max-width: 80vw;
        }
        height: auto;
      }
    }

    @include breakpoints.breakpoint(mobile, max) {
      flex-direction: column;
      gap: 2rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      &__screenshot {
        order: 2;
      }
      &__login_widget {
        order: 1;
      }
    }

    padding-bottom: 6rem;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    // align-content: center;

    gap: 1.5rem;

    &__testimonial {
      position: relative;

      svg {
        z-index: -99;
        position: absolute;
        top: -1rem;
        left: 0px;
      }
      p {
        max-width: 300px;

        flex-basis: 25%;
        color: colors.get("neutral", 0);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: start;
      }
    }
  }
}
