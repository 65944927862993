@use "../../theme/colors";
@use "../../theme/shadows";
@use "../../theme/breakpoints";

.burst_detail_card {
  background-color: colors.get("neutral", 0);
  &-shadow {
    box-shadow: shadows.get("card");
  }
  border-radius: 8px;
  margin-bottom: 16px;
  font-family: "Poppins", sans-serif;

  &-chart-wrapper {
    overflow-x: scroll;
    overflow: -moz-scrollbars-horizontal;
    &-scroll {
      margin-left: -40px;
      overflow-x: scroll;
      min-width: 100%;
      // @include breakpoints.breakpoint(tablet, min) {
      //   min-width: 800px;
      // }
    }
    &-scroll-long {
      margin-left: -38px;
      overflow-x: scroll;
      min-width: 120vw;
      // @include breakpoints.breakpoint(tablet, min) {
      //   min-width: 800px;
      // }
    }
  }
  &-wrapper {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 10px;

    &-item {
      .text-align-left {
        text-align: left;
      }
      .right {
        justify-content: right;
      }
      .mr {
        margin-right: 20px;
      }
      .fw-600 {
        font-weight: 600;
      }
      .fs-18 {
        font-size: 18px;
      }
      .lh {
        line-height: 48px;
      }
      .no-mr {
        margin-right: 0px;
      }
      .fw-400 {
        font-weight: 400;
      }
      .fw-600 {
        font-weight: 600;
      }
      &-label {
        span:first-child {
          font-weight: 600;
        }
        margin: 0;
        margin-right: 8px;
        text-align: right;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: colors.get("primary", 800);
      }

      &-container {
        display: flex;

        &-value {
          font-size: 18px;
          line-height: 40px;
          font-weight: 700;
          margin: 0 4px 0 0;
          color: colors.get("primary", 800);
        }

        &-unit {
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          margin: 10px 0 0 0;
          color: colors.get("primary", 800);
        }

        .left {
          color: colors.get("neutral", 500);
          font-size: 14px;
        }
      }
    }
  }
}
