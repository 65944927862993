@use "theme/reset";
@use "theme/colors";
@use "theme/shadows";
@use "theme/typography";

html {
  @each $color_name, $tones in colors.$colors {
    @each $color_tone_name, $color in $tones {
      --#{unquote($color_name)}-#{unquote($color_name)}-#{$color_tone_name}: #{$color};
    }
  }
}

.App {
  display: flex;
  justify-content: center;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: colors.get("neutral", 900);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: colors.get("neutral", 0);
}

.App-link {
  color: colors.get("secondary", 200);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app {
  &-watermark {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 77vw;
    right: 0;
    display: flex;
    justify-content: center;
    &-text {
      color: colors.get("neutral", 0);
      font-family: "Poppins";
      text-shadow: 1px 1px 2px colors.get("primary", 800), 0 0 1em colors.get("secondary", 800);
      font-size: small;
      font-weight: bolder;
    }
  }
  &-version {
    display: none;
    color: transparent;
  }
  &-debug {
    z-index: 998;
    position: fixed;
    top: 0;
    right: 0;
    background-color: colors.get("neutral", 900);
    color: colors.get("neutral", 900);
    font-family: "Poppins";
    font-size: small;
    width: 96vw;
    height: 98vh;
    overflow: scroll;
    border: 2px solid colors.get("primary", 500);
  }
}
