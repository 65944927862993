@use "../../theme/colors";
@use "../../theme/breakpoints";

.burst {
  width: 100%;
  flex-grow: 1;
  font-family: "Poppins", sans-serif;

  @include breakpoints.breakpoint(tablet, min) {
    margin-top: 40px;
    display: flex;
    position: relative;
    justify-content: center;
    gap: 16px;
  }

  &-content {
    text-align: left;
    background-color: colors.get("neutral", 0);
    &-container {
      padding-top: 16px;
      .tab {
        margin-right: 16px;
        margin-left: 16px;
      }
      @include breakpoints.breakpoint(tablet, min) {
        padding: 16px;
      }
    }
    height: fit-content;

    &-section {
      &-container {
        z-index: 9;
      }
      &-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        padding: 0px 8px;
      }
      &-left-arrow {
        width: 16px;
        z-index: 10;
        cursor: pointer;
        height: 28px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
      &-right-arrow {
        width: 16px;
        z-index: 10;
        cursor: pointer;
        cursor: inherit;
        height: 28px;
      }

      &-title {
        padding: 0px 16px 0px 16px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: colors.get("neutral", 600);
        .cough_label {
          margin-bottom: 0px;
          padding-bottom: 12px;
        }
      }

      &-no-data {
        display: flex;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        min-height: 420px;
        position: relative;
        max-width: 350px;
        @include breakpoints.breakpoint(tablet, min) {
          max-width: tablet;
          min-height: 300px;
        }
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        color: colors.get("neutral", 800);
        .cough_label {
          margin-bottom: 0px;
          padding-bottom: 12px;
        }
        span {
          text-align: center;
        }
        h3 {
          padding-top: 100px;
          color: colors.get("primary", 900);
        }
      }
    }
    &-section-row {
      flex-direction: row;
      justify-content: center;
    }
  }

  &-wrapper {
    height: fit-content;

    background-color: colors.get("neutral", 50);

    @include breakpoints.breakpoint(tablet, min) {
      min-width: 400px;
      padding-top: 16px;
      background-color: colors.get("neutral", 0);
    }

    &-reference {
      width: 100%;
      .reference-bar {
        padding-bottom: 4px;
        border-bottom: 1px solid colors.get("neutral", 100);
        @include breakpoints.breakpoint(tablet, min) {
          border-bottom: none;
        }
      }
    }

    &-history {
      width: 100%;
      padding: 4px 16px 16px 16px;
      &-label {
        font-weight: 700;
        color: colors.get("neutral", 500);
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 12px;
      }

      &-item {
        padding: 12px 0px;
        display: flex;
        justify-content: space-between;

        &-label {
          color: colors.get("neutral", 800);
          font-size: 16px;
          line-height: 22px;
          font-weight: 400;
        }

        &-value {
          font-size: 18px;
          color: colors.get("primary", 800);
          line-height: 26px;
          font-weight: 500;
        }

        &-value-not {
          font-size: 14px;
          line-height: 20px;
          color: colors.get("neutral", 400);
        }
      }
    }
  }
}
