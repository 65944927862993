@use "../../theme/colors";
@use "../../theme/typography";
@use "../../theme/shadows";
@use "../../theme/breakpoints";

.navbar {
  width: 100%;
  position: relative;

  &__drawer {
    &__title {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }

    &__items {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__item {
      width: 100%;
      @extend .subheading-1;
      line-height: 150%;
      display: flex;
      flex-direction: row;
      gap: 1rem;

      text-decoration: none;
      cursor: pointer;

      color: colors.get("primary", 600);
      transition: color 0.2s ease-in-out;

      position: relative;

      svg,
      img {
        width: 2rem;
        height: 2rem;
      }
      &:hover {
        color: colors.get("primary", 400);
      }
    }
  }

  &__level1__wrapper {
    width: 100%;
    background-color: colors.get("neutral", 0);
    box-shadow: shadows.get("top_nav");

    height: 60px;
    padding: 0 10px;
    position: relative;
    z-index: 100000;
  }

  &__level1 {
    max-width: 1100px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &__logo {
      &_large {
        height: 40px;
        margin: auto;
        margin-right: 2rem;
        @include breakpoints.breakpoint(tablet, max) {
          display: none;
        }
      }
      &_small {
        height: 40px;
        margin: auto;
        margin-right: 1rem;
        display: none;
        @include breakpoints.breakpoint(tablet, max) {
          display: block;
        }
      }
    }

    &__burger {
      display: flex;
      justify-content: center;
      align-items: center;
      color: colors.get("primary", 500);
      margin-right: 1rem;
    }

    &__tabs_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      gap: 2.5rem;

      @include breakpoints.breakpoint(tablet, max) {
        gap: 1rem;
      }
    }

    &__tab_item {
      position: relative;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
      text-decoration: none;

      & > * {
        @extend .subheading-1;
        color: colors.get("neutral", 700);
      }

      &--selected > * {
        color: colors.get("primary", 500);
      }

      &--selected::after {
        content: "";
        position: absolute;

        bottom: -6px;
        left: 0px;
        right: 0px;
        border-bottom: 4px solid colors.get("primary", 500);
      }

      @include breakpoints.breakpoint(tablet, max) {
        &__text {
          display: none;
        }
      }
    }

    &__footer_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
    }

    &__user_avatar {
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      border-radius: 50%;
    }
  }

  &__level2 {
    background-color: colors.get("neutral", 0);
    padding: 22px 0 22px 0;

    box-shadow: shadows.get("top_nav");

    &-wrapper {
      display: flex;
      justify-content: center;
      position: relative;

      &-title {
        color: colors.get("primary", 800);
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        font-family: "Poppins", sans-serif;
      }

      &-action {
        position: absolute;
        left: 16px;
        top: 2.5px;
        cursor: pointer;
        &-icon {
          filter: brightness(0%) contrast(100%) invert(28%) sepia(17%) saturate(1972%) hue-rotate(176deg);
        }
      }
    }

    &-desktop {
      position: absolute;
      top: 10px;
      right: 10px;

      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
  }
}
