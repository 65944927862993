@use "colors";

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}
* {
  box-sizing: border-box;
  font-family: Poppins;
}

h1 {
  //styleName: Heading/H1;
  font-family: Poppins;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0em;
  text-align: left;
  user-select: none;
}
h2 {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  user-select: none;
}
h3 {
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  user-select: none;
}

h4 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  user-select: none;
}

.subheading-1 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.subheading-2 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.text-large-regular {
  /* Body/Large/Regular */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}

.text-medium-regular {
  /* Body/Medium/Regular */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.text-small-regular {
  /* Body/Small/Regular */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

p,
li,
ol {
  color: colors.get("neutral", 600);
  text-align: center;

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
