@use "../../theme/colors";
@use "../../theme/breakpoints";

.burst_history_graph {
  margin: 8px 0 30px 0;
  font-family: "Poppins", sans-serif;
  &-container {
    position: relative;
    display: grid;
    height: 100px;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
  }
}
