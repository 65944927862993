@use "../../theme/colors";
@use "../../theme/shadows";

.report {
  width: 100%;
  max-width: 450px;
  height: calc(100vh - 95px);
  overflow: auto;
  font-family: "Poppins", sans-serif;

  &-content {
    text-align: left;
    padding-bottom: 50px;
    background-color: colors.get("neutral", 0);

    &-header {
      padding: 16px 16px 24px 16px;
      text-align: center;
      background-color: colors.get("neutral", 50);

      &-title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        color: colors.get("neutral", 600);
        margin-bottom: 12px;
      }

      &-desc {
        font-size: 14px;
        line-height: 20px;
        color: colors.get("neutral", 900);
        margin-bottom: 24px;
        text-align: left;
      }

      &-download {
        border: 1px solid colors.get("primary", 500);
        color: colors.get("primary", 500);
        font-weight: 700;
        padding: 14px 24px;
        cursor: pointer;
        border-radius: 30px;
        background-color: colors.get("neutral", 0);

        img {
          margin-right: 10px;
        }
      }
    }

    &-page {
      padding: 16px 16px 24px 16px;
      text-align: center;
      background-color: colors.get("neutral", 50);
      &-title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        color: colors.get("neutral", 600);
        margin-bottom: 12px;
      }
      &-content {
        font-size: 14px;
        line-height: 20px;
        color: colors.get("neutral", 900);
        margin-bottom: 24px;
        text-align: left;
      }
    }

    &-main {
      padding: 24px 16px 16px 16px;
      background-color: colors.get("neutral", 0);
      &-chart {
        padding: 25px 0px;
      }

      &-area-chart {
        padding-top: 16px;
        padding-bottom: 40px;
        &-title {
          display: flex;
          flex-direction: row;
          .date-picker {
            margin-top: 0px;
          }
          .cough_label {
            margin: 0 auto;
            padding: 0px 16px 0px 0px;
            width: 50%;
          }
        }
      }

      &-label {
        color: colors.get("neutral", 800);
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
      }

      &-desc {
        margin-top: 8px;
        color: colors.get("neutral", 600);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
    }

    &-footer {
      display: flex;
      justify-content: center;
      padding: 20px 0 32px 0;

      &-btn {
        box-shadow: shadows.get("button");
        background-color: colors.get("primary", 500);
        border-radius: 24px;
        color: colors.get("neutral", 0);
      }

      &-download {
        border: 1px solid colors.get("primary", 500);
        color: colors.get("primary", 500);
        font-weight: 700;
        padding: 14px 24px;
        cursor: pointer;
        border-radius: 30px;
        background-color: colors.get("neutral", 0);

        img {
          margin-right: 10px;
        }
      }
    }
  }
}
