@use "../../theme/colors";

.tab {
  display: flex;
  font-family: "Poppins", sans-serif;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: colors.get("neutral", 50);

  &-item {
    cursor: pointer;
    flex: 1;
    padding: 7px;
    line-height: 18px;
    color: colors.get("neutral", 500);
    font-size: 14px;
    font-weight: 500;
    text-align: center;

    &-active {
      background-color: colors.get("primary", 100);
      color: colors.get("primary", 700);;
      border-radius: 4px;
    }
  }
}
