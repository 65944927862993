@use "../../theme/breakpoints";

.clock-chart-wrapper {
  height: 90vw;
  width: 90vw;

  @include breakpoints.breakpoint(mobile, min) {
    width: max(30vw, 500px);
    height: max(30vw, 500px);
  }
  position: relative;
  &-chart {
    position: absolute;
    &-daily,
    &-weekly,
    &-monthly {
      position: absolute;
    }
  }
}
