@use "../../theme/typography";
@use "../../theme/colors";
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100svh;
  background: var(--gradient-gradient-primary, linear-gradient(119deg, #294a9c 0%, #2f86ac 100%));
  z-index: 0;

  &_container {
    width: 100%;
    flex-grow: 1;
    background-color: colors.get("neutral", 50);
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
