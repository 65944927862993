@use "../../theme/colors";
@use "../../theme/shadows";

.burst-detail-card {
  background: colors.get("neutral", 0);
  padding: 16px;
  margin-top: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: shadows.get("card");
  text-align: center;
}
